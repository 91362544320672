// @refresh reload
import { mount, StartClient } from '@solidjs/start/client';
import { PersistentClient } from './shared/PersistentClient';
import { isServer } from 'solid-js/web';

export default mount(() => <StartClient />, document.getElementById('app')!);

// Here is where we call any web client side initialization code for this page:
// We initialize the PersistentClient, which handles shared code, communication and proxy calling
if (!isServer) {
  // I will only run on the browser
  PersistentClient.initialize();
}
